import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { GetServerSidePropsContext } from 'next';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useCallback } from 'react';
import styled from 'styled-components';
import IconBtcfi from '@/assets/icons/icon-btcfi';
import Faq from '@/components/Landing/Faq';
import HowToWorksMobileSlider from '@/components/Landing/HowToWorksMobileSlider';
import HowToWorksSlider from '@/components/Landing/HowToWorksSlider';
import IntroSlider from '@/components/Landing/IntroSlider';
import Partners from '@/components/Landing/Partners';
import { CountingData } from '@/components/atom/CountingData';
import { FlexBox } from '@/components/atom/FlexBox';
import { GlassPanel } from '@/components/atom/GlassPanel';
import { StyledText } from '@/components/atom/Text';
import { LINK_VIP } from '@/configs/link';
import {
  MEDIA_QUERY_WIDTH_DESKTOP_1200,
  MEDIA_QUERY_WIDTH_DESKTOP_MOBILE,
  MEDIA_QUERY_WIDTH_DESKTOP_S,
  MEDIA_QUERY_WIDTH_MOBILE_M,
  MEDIA_QUERY_WIDTH_MOBILE_S,
} from '@/configs/mediaQuery';
import { PATH_DASHBOARD } from '@/configs/path';
import { VIDEO_PATH_WAVE, VIDEO_URL_ORIGIN } from '@/configs/videoUrl';
import useBtcfiMarket from '@/hooks/api/useBtcfiMarket';
import useGetCurrencyString from '@/hooks/store/useGetCurrencyString';
import useEnv from '@/hooks/useEnv';
import useVerticalScrollDetector from '@/hooks/useVerticalScrollDetector';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { eventLaunchAppClick } from '@/libs/gtag';
import { appInitAtom } from '@/store/uiStore';
import { Theme } from '@/styles/theme';
import { getIsMobile } from '@/utils/mobileDetect';

const IntroSection = styled(FlexBox)`
  padding: 80px 40px 256px 40px;
  background: radial-gradient(
    29.56% 47.33% at 71.56% 50%,
    #1f3da1 0%,
    #02021c 100%
  );
  gap: 80px;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    padding: 80px 48px 160px 48px;
    gap: 40px;
  }

  @media (${MEDIA_QUERY_WIDTH_MOBILE_M}) {
    padding: 80px 16px 160px 16px;
  }

  .swiper-pagination {
    transform: translateY(102px);
  }

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background: ${(p) => p.theme.colors.white};
  }

  .swiper-pagination-bullet + .swiper-pagination-bullet {
    margin-left: 16px;
  }

  .swiper-pagination-bullet-active {
    background: ${(p) => p.theme.colors.point};
    border: 1px solid #f8f8f866;
  }

  .swiper-wrapper {
    z-index: 0;
  }
`;

const PartnerSection = styled(FlexBox)`
  padding: 160px 0;
  background-color: ${(p) => p.theme.colors.black};
  overflow: hidden;

  @media (${MEDIA_QUERY_WIDTH_MOBILE_M}) {
    padding: 120px 0;
  }
`;

const HowToWorkSection = styled(FlexBox)`
  padding: 160px 40px;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    padding: 160px ${(p) => p.theme.size.xl};

    > div {
      gap: 40px;
    }

    h2 {
      font-size: ${(p) => p.theme.size.xxxl};
    }

    span {
      font-size: ${(p) => p.theme.size.md};
    }
  }

  @media (${MEDIA_QUERY_WIDTH_MOBILE_M}) {
    padding: 120px ${(p) => p.theme.size.xl};
  }
`;

const FaqSection = styled(FlexBox)`
  padding: 160px 40px;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    padding: 160px 48px;
  }

  @media (${MEDIA_QUERY_WIDTH_MOBILE_M}) {
    padding: 120px ${(p) => p.theme.size.xl};
  }
`;

const TotalValueLocked = styled(GlassPanel)<{ $isScrolled: boolean }>`
  background: radial-gradient(
    76.77% 80.51% at 100% 0%,
    rgba(31, 61, 161, 0.7) 0%,
    rgba(2, 2, 28, 0.7) 100%
  );
  align-self: flex-end;
  position: absolute;
  bottom: 80px;
  border: 1px solid #f8f8f866;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    align-self: flex-start;
  }

  @media (${MEDIA_QUERY_WIDTH_MOBILE_M}) {
    position: relative;
    padding: ${(p) => p.theme.size.md};
    bottom: 0;
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    strong {
      font-size: 16px;
    }
  }
`;

const LaunchLink = styled(Link)<{
  color?: keyof Theme['colors'];
  background?: keyof Theme['bg'];
}>`
  display: flex;
  position: relative;
  gap: 4px;
  height: 48px;
  padding: ${(p) => p.theme.size.sm};
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  overflow: hidden;
  isolation: isolate;
  color: ${({ theme, color }) => theme.colors[color ?? 'textWhite']};
  font-size: ${(p) => p.theme.size.md};
  ${(p) => p.theme.font.bold}
  line-height: ${(p) => p.theme.size.xxl};
  border: 1px solid #f8f8f866;
  ${({ theme, background }) => theme.bg[background ?? 'transparent']}

  @media (${MEDIA_QUERY_WIDTH_MOBILE_M}) {
    font-size: ${(p) => p.theme.size.md};
  }
`;

const VideoWrapper = styled(FlexBox)<{ $isScrolled?: boolean }>`
  width: 100%;
  height: 100%;
  transition: all 0.3s linear;
  transform-style: preserve-3d;

  video {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    ${(p) =>
      p.$isScrolled &&
      `
    border-radius: 48px;
  `}
  }

  & > div {
    width: 100%;
    transition: all 0.3s linear;
    position: relative;
    max-width: 1920px;
    padding: 80px;
    justify-content: center;
  }

  @media (max-width: 1079px) {
    & > div {
      padding: ${(p) => p.$isScrolled && '80px 16px'};
    }
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    & > div {
      padding: ${(p) => !p.$isScrolled && '80px 48px'};
    }
  }

  @media (${MEDIA_QUERY_WIDTH_MOBILE_M}) {
    & > div {
      padding: ${(p) => !p.$isScrolled && '80px 16px'};
    }
  }
`;

const VideoSection = styled(FlexBox)<{ $isScrolled?: boolean }>`
  position: relative;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100vh;
  transition: all 0.3s linear;
  overflow: hidden;
  will-change: padding, height;
  background-color: #02021c;

  @media (max-width: 1079px) {
    ${(p) =>
      p.$isScrolled &&
      `
      height: 900px;
      padding: 104px 16px 16px 16px;
  `}
  }

  @media (max-width: 918px) {
    @media (max-height: 592px) {
      height: unset;
    }
  }

  @media (max-width: 640px) {
    ${(p) =>
      p.$isScrolled &&
      `
      height: 750px;
  `}
  }

  @media (${MEDIA_QUERY_WIDTH_MOBILE_M}) {
    ${(p) =>
      p.$isScrolled &&
      `
      height: 100vh;
  `}
  }

  @media (max-height: 615px) {
    height: unset;
  }

  ${(p) =>
    p.$isScrolled &&
    `
      height: 928px;
      padding: 128px 48px 80px 48px;
  `}
`;

const UtilText = styled(StyledText)`
  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    white-space: pre-wrap;
  }
  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    font-size: ${(p) => p.theme.size.xxxl};
  }
`;

const UtilSubtitleText = styled(StyledText)`
  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    font-size: ${(p) => p.theme.size.md};
  }
`;

const TitleText = styled(StyledText)<{ $language: string }>`
  word-break: keep-all;
  @media (${MEDIA_QUERY_WIDTH_DESKTOP_1200}) {
    white-space: ${(p) => p.$language === 'en' && 'pre-line'};
  }

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_S}) {
    white-space: ${(p) => p.$language === 'ko' && 'pre-line'};
  }

  @media (max-width: 640px) {
    font-size: 32px;
  }
`;

const SubtitleText = styled(StyledText)<{
  language: string;
  $isScrolled: boolean;
}>`
  transition: all 0.3s linear;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_MOBILE}) {
    width: calc(100vw - 64px);
  }

  @media (${MEDIA_QUERY_WIDTH_MOBILE_S}) {
    width: 100%;
    white-space: unset;
  }

  @media (max-width: 1079px) {
    white-space: ${(p) => p.language === 'en' && 'unset'};
  }

  @media (max-width: 640px) {
    font-size: 16px;
  }
`;

const TVLTitleText = styled(StyledText)`
  @media (${MEDIA_QUERY_WIDTH_MOBILE_M}) {
    font-size: ${(p) => p.theme.size.md};
  }
`;

const TitleContainer = styled(FlexBox)`
  margin-bottom: 170px;

  @media (${MEDIA_QUERY_WIDTH_DESKTOP_1200}) {
    margin-bottom: 146px;
  }

  @media (${MEDIA_QUERY_WIDTH_MOBILE_M}) {
    flex-grow: unset;
    margin-bottom: 0;
  }
`;

const Wrapper = styled(FlexBox)`
  word-break: keep-all;
`;

const transitionDelay = 3;

interface Props {
  isCarousalReverse: boolean;
  isMobile: boolean;
}

const VideoBackground = dynamic(
  () => import('@/components/Landing/VideoBackground'),
  {
    ssr: false,
  }
);

export default function Home({ isCarousalReverse, isMobile }: Props) {
  const { t } = useTranslation('landing');

  const { isTestnet, languageId } = useEnv();

  const { tvlValue } = useBtcfiMarket();

  const { currencySymbol } = useGetCurrencyString();

  const { isScrolled } = useVerticalScrollDetector();

  const initAppInit = useAtomValue(appInitAtom);

  const handleLaunchClick = useCallback(
    () => eventLaunchAppClick(isTestnet),
    [isTestnet]
  );

  return (
    <Wrapper style={{ background: '#0c0c20', overflowX: 'hidden' }}>
      <VideoSection as="section" $isScrolled={isScrolled}>
        <VideoWrapper $flex $isScrolled={isScrolled}>
          <VideoBackground bgVideo={`${VIDEO_URL_ORIGIN}${VIDEO_PATH_WAVE}`} />
          <FlexBox
            $flex
            $direction="column"
            $gap={40}
            style={{ margin: '0 auto' }}
          >
            <TitleContainer
              as={motion.div}
              $flex
              $grow={1}
              $direction="column"
              $align="flex-start"
              $justify="center"
              $gap={40}
              initial={{ y: 20, opacity: 0 }}
              whileInView={{
                y: 0,
                opacity: 1,
                transition: {
                  type: 'keyframes',
                  duration: 1,
                  delay: initAppInit ? 0 : transitionDelay,
                },
              }}
              viewport={{ once: true }}
            >
              <TitleText
                as="h1"
                $fontSize={64}
                $fontWeight="black"
                $language={languageId}
              >
                {t('earnYourBitcoin')}
              </TitleText>
              <SubtitleText
                as={motion.p}
                $fontSize="xl"
                $whiteSpace="pre-line"
                $isScrolled={isScrolled}
                language={languageId}
              >
                {t('generateAdditionalRevenue')}
              </SubtitleText>
              <FlexBox $flex $gap="lg" $align="center" $wrap="wrap">
                <LaunchLink
                  background="active"
                  href={PATH_DASHBOARD}
                  onClick={handleLaunchClick}
                >
                  {t('launchBtcfi')}
                </LaunchLink>
                <LaunchLink
                  color="textBlack"
                  background="whiteBackground"
                  href={LINK_VIP}
                >
                  <IconBtcfi />
                  {t('exploreNewBtcfi')}
                </LaunchLink>
              </FlexBox>
            </TitleContainer>
            <TotalValueLocked
              as={motion.div}
              $flex
              $direction="column"
              $padding="xl"
              $rounded="xl"
              $isScrolled={isScrolled}
              initial={{ y: 20, opacity: 0 }}
              whileInView={{
                y: 0,
                opacity: 1,
                transition: {
                  type: 'keyframes',
                  duration: 1,
                  delay: initAppInit ? 0 : transitionDelay,
                },
              }}
              viewport={{ once: true }}
            >
              <FlexBox $flex $gap="xxxs" $align="center">
                <IconBtcfi />
                <TVLTitleText as="strong" $fontSize="xl" $fontWeight="bold">
                  TOTAL VALUE LOCKED
                </TVLTitleText>
              </FlexBox>
              <CountingData
                value={tvlValue}
                decimal={0}
                prefix={currencySymbol}
                isScrolled={isScrolled}
                delay={initAppInit ? 0 : transitionDelay}
              />
            </TotalValueLocked>
          </FlexBox>
        </VideoWrapper>
      </VideoSection>
      <IntroSection as="section" $flex $align="center" $direction="column">
        <FlexBox
          as={motion.div}
          $flex
          $gap="xs"
          $align="center"
          $direction="column"
          initial={{ y: 20, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              type: 'keyframes',
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          <UtilText
            as="h2"
            $fontSize={64}
            $fontWeight="black"
            $textAlign="center"
          >
            {t('utilizeYourBitcoin')}
          </UtilText>
          <UtilSubtitleText
            as="p"
            $fontSize="xl"
            $textColor="textGray"
            $textAlign="center"
            $whiteSpace="pre-line"
          >
            {t('whyUseBtcfi')}
          </UtilSubtitleText>
        </FlexBox>
        <IntroSlider isReverse={isCarousalReverse} />
      </IntroSection>
      <PartnerSection as="section">
        <Partners />
      </PartnerSection>
      <HowToWorkSection as="section">
        <FlexBox $flex $direction="column" $align="center" $gap={80}>
          <FlexBox
            as={motion.div}
            $flex
            $direction="column"
            $align="center"
            $gap="xs"
            initial={{ y: 20, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                type: 'keyframes',
                duration: 1,
              },
            }}
            viewport={{ once: true }}
          >
            <StyledText as="h2" $fontSize={64} $fontWeight="black">
              {t('howBTCfiWorks')}
            </StyledText>
            <StyledText
              $fontSize="xl"
              $textColor="textGray"
              $textAlign="center"
            >
              {t('btcfiIsaStaking')}
            </StyledText>
          </FlexBox>
          <HowToWorksSlider />
          <HowToWorksMobileSlider />
        </FlexBox>
      </HowToWorkSection>
      <FaqSection $flex $justify="center">
        <Faq />
      </FaqSection>
    </Wrapper>
  );
}

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const isMobile = getIsMobile(context);
  const isCarousalReverse = Math.random() < 0.5;

  return {
    props: {
      ...(await serverSideTranslations(context.locale as string, [
        'common',
        'landing',
      ])),
      isCarousalReverse,
      isMobile,
    },
  };
};
