import ImageAlex from '@/assets/images/partners/partners-alex.png';
import ImageAvalanche from '@/assets/images/partners/partners-avalanche.png';
import ImageChainlink from '@/assets/images/partners/partners-chainlink.png';
import ImageCoinplug from '@/assets/images/partners/partners-coinplug.png';
import ImageConflux from '@/assets/images/partners/partners-conflux.png';
import ImageCore from '@/assets/images/partners/partners-core.png';
import ImageCube from '@/assets/images/partners/partners-cube.png';
import ImageDelight from '@/assets/images/partners/partners-delight.png';
import ImageDespread from '@/assets/images/partners/partners-despread.png';
import ImageEl from '@/assets/images/partners/partners-el.png';
import ImageJoc from '@/assets/images/partners/partners-joc.png';
import ImageKanalabs from '@/assets/images/partners/partners-kanalabs.png';
import ImageKica from '@/assets/images/partners/partners-kica.png';
import ImageNeo from '@/assets/images/partners/partners-neo.png';
import ImageOpensea from '@/assets/images/partners/partners-opensea.png';
import ImageOzys from '@/assets/images/partners/partners-ozys.png';
import ImagePlaydapp from '@/assets/images/partners/partners-playdapp.png';
import ImagePolygon from '@/assets/images/partners/partners-polygon.png';
import ImageSac from '@/assets/images/partners/partners-sac.png';
import ImageSix from '@/assets/images/partners/partners-six.png';
import ImageSlash from '@/assets/images/partners/partners-slash.png';
import ImageStacks from '@/assets/images/partners/partners-stacks.png';
import ImageSynfutures from '@/assets/images/partners/partners-synfutures.png';
import ImageTheori from '@/assets/images/partners/partners-theori.png';
import ImageThepol from '@/assets/images/partners/partners-thepol.png';
import ImageXlink from '@/assets/images/partners/partners-xlink.png';
// import ImageGaia from '@/assets/images/partners/partners-gaia.png';

export const PARTNERS = [
  { name: 'Core', image: ImageCore },
  { name: 'Joc', image: ImageJoc },
  { name: 'Slash', image: ImageSlash },
  { name: 'Synfutures', image: ImageSynfutures },
  { name: 'Thepol', image: ImageThepol },
  { name: 'Neo', image: ImageNeo },
  { name: 'Conflux', image: ImageConflux },
  { name: 'Stacks', image: ImageStacks },
  { name: 'Theori', image: ImageTheori },
  { name: 'Alex', image: ImageAlex },
  { name: 'Xlink', image: ImageXlink },
  { name: 'Cube', image: ImageCube },
  // { name: 'Gaia', image: ImageGaia },
  { name: 'Delight', image: ImageDelight },
  { name: 'Ozys', image: ImageOzys },
  { name: 'El', image: ImageEl },
  { name: 'Avalanche', image: ImageAvalanche },
  { name: 'Six', image: ImageSix },
  { name: 'Despread', image: ImageDespread },
  { name: 'Sac', image: ImageSac },
  { name: 'Kica', image: ImageKica },
  { name: 'Opensea', image: ImageOpensea },
  { name: 'Polygon', image: ImagePolygon },
  { name: 'Kanalabs', image: ImageKanalabs },
  { name: 'Coinplug', image: ImageCoinplug },
  { name: 'Playdapp', image: ImagePlaydapp },
  { name: 'Chainlink', image: ImageChainlink },
];
